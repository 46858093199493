import { MetaFunction, useLocation } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { useEffect } from 'react';
import RobinPage from '~/components/robin/RobinPage';

import { Route } from '~/config/enums';
import { generateMetaTags } from '~/services/utils/meta';

export const meta: MetaFunction = () => {
  return generateMetaTags({ title: '404 Not Found', route: Route.NotFound });
};

export default function NotFoundPage() {
  const location = useLocation();

  useEffect(() => {
    captureRemixErrorBoundaryError(new Error(`[404] Route not found ${location.pathname}`));
  }, [location]);

  return (
    <RobinPage pageTitle={'Page Not Found'} buttonText={'Go back'} icon={'back'}>
      <h2 className="robin-demibold-24 text-black text-center">
        Uh oh!
        <br />
        Sounds like you are lost!
      </h2>
      <p className="robin-medium-16 text-rf-gray-75">This page does not exist.</p>
    </RobinPage>
  );
}
